import React, { Fragment, PureComponent } from 'react'
import { Layout, Jumbotron, Marks } from '../../../components'
import '../../style.less'

class CurryNight extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Traditional Sunday Lunch'}
          location={this.props.location}
        >
          <Jumbotron
            heading={'Traditional Sunday Lunch'}
            image={'sunday.jpg'}
          />
          <div className='container'>
            <Marks />
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h2'>
                  <h2>Roast Menu</h2>
                  <b>All of our roasts are served with Beef Dripping Roast Potatoes, Homemade Yorkshire Pudding &amp; Homemade Stuffing</b>
                  <p className='gf-mark'>We Have Gluten Free Yorkshire Puddings &amp; Stuffing Available Upon Request. Our Gravy Is Also Gluten Free</p>
                  <h4>Please Ask Your Server For Toady’s Selection Of Meats, Our Choices Include.</h4>
                  <p>Our usual menu is available on Sunday&apos;s as well.</p>
                </div>
                <div className='para-h4'>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className=''>Roast Topside Of Beef<div className='pull-right' style={{ fontSize: '18px' }}>£15.52</div></h4>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>Roast Loin Of Pork<div className='pull-right' style={{ fontSize: '18px' }}>£14.95</div></h4>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>Roast Breast Of Turkey<div className='pull-right' style={{ fontSize: '18px' }}>£14.95</div></h4>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>Slow Roasted Leg Of Lamb<div className='pull-right' style={{ fontSize: '18px' }}>£18.95</div></h4>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>Roast Gammon<div className='pull-right' style={{ fontSize: '18px' }}>£14.95</div></h4>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>Mixed Meat Roast</h4>
                      <p>Enjoy A Selection Of Three Of Your Favourite Meats From Today&apos;s Selection. <div className='pull-right' style={{ fontSize: '18px' }}>£17.95</div></p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>Meat Free Roast</h4>
                      <p>Served With Homemade Yorkshire Puddings, Homemade Stuffing &amp; Cauliflower Cheese<div className='pull-right' style={{ fontSize: '18px' }}>£13.95</div></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row '>
              <div className='col-md-12 col-xs-12 mb-4 para-h4'>
                <h2 className='para-h2'>Roast Side Orders</h2>
                <div className='row '>
                  <div className='col-md-12 col-xs-12'>
                    <h4>Homemade Cauliflower Cheese <span className='vegetarian-mark'>V</span> </h4>
                    <div className='row'>
                      <div className='col-md-10 col-xs-10'>
                        <div className='mt-0'>Small Serves 1-2 People</div>
                      </div>
                      <div className='col-md-2 col-xs-2'>
                        <div className='pull-right' style={{ fontSize: '18px' }}>£2.95</div>
                      </div>
                      <div className='col-md-10 col-xs-10'>
                        <div className='mt-0'>Regular Serves 2-3 People</div>
                      </div>
                      <div className='col-md-2 col-xs-2'>
                        <div className='pull-right' style={{ fontSize: '18px' }}>£3.95</div>
                      </div>
                      <div className='col-md-10 col-xs-10'>
                        <div className='mt-0'>Large Serves 4-6 People</div>
                      </div>
                      <div className='col-md-2 col-xs-2'>
                        <div className='pull-right' style={{ fontSize: '18px' }}>£5.25</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <h4>4 Large Pigs in Blankets <div className='pull-right' style={{ fontSize: '18px' }}>£5.25</div></h4>
                  </div>
                  <div className='col-md-12 col-xs-12'>
                    <h4>Extra Roast Potatoes <div className='pull-right' style={{ fontSize: '18px' }}>£3.50</div></h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Layout >
      </Fragment>
    )
  }
}

export default CurryNight
